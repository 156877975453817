@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.CollapsibleContent {
  overflow: hidden;
}
.CollapsibleContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}
.CollapsibleContent[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    width: 0;
  }
  to {
    width: var(--radix-collapsible-content-width);
  }
}

@keyframes slideUp {
  from {
    width: var(--radix-collapsible-content-width);
  }
  to {
    width: 0;
  }
}

/* Scrollbar Styles */

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #757575 #DFE9EB;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 6px;
  width: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #d2d7d8;
}

*::-webkit-scrollbar-track:active {
  background-color: #d2d7d8;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #757575;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #606060;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #606060;
}
