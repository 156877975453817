@keyframes expand {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .expanding-emoji {
    @apply text-lg;
    animation: expand 2s ease-in-out infinite;
  }
  